.ap-exam-form-container {
    margin: 10px 16px;
}

.ap-exam-dropdowns {
    width: 100%;
}

.ap-exam-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.ap-exam-form-wrapper .btn {
    margin-top: 10px;
}

.ap-exam-required-fields-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.4rem;
}

#ap-exam-name {
    min-width: 60%;
    flex-grow: 1;
}

#ap-exam-units > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

#ap-exam-units > div > input {
    width: 63px;
    height: 36px;
    text-align: right;
    font-size: 16px;
    padding-right: 5px;
    padding-left: 1rem;
    box-shadow: none;
    border-radius: 1.6rem;
    border: 1px solid #1F1F1F;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    margin-left: 6px;
}

#ap-exam-units .information-icon {
    color: var(--accent-color-2);
}

.ap-exam-input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1px;
}

.ap-exam-input-wrapper > label {
    font-size: 1.4rem;
    margin-bottom: 5px;
    padding-left: 12px;
}