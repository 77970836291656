.remove-year-btn {
    display: none;
    left: 7rem;
    top: 0rem;
    color: var(--accent-color-1);
}

.remove-year-btn svg:hover {
    box-shadow: 0 0 2px 2px var(--accent-color-1);
    border-radius: 50%;
    background-color: var(--accent-color-1);
    color:white;
}   

@media print { 
    .remove-year-btn {display: none;}
}