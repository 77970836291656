.switch {
    position: relative;
    display: inline-block;
    width: 31px;
    height: 18px;
    margin-right: 0.5rem;
}

.switch > input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 26px;
    border: 1px solid #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;

    left: 1px;
    bottom: 1px;
    border-radius: 50%;
    background-color: #ccc;
    transition: 0.4s;
}

input:checked + .slider {
    border: 1px solid var(--accent-color-2);
}

input:checked + .slider:before {
    background-color: var(--accent-color-2);
    transform: translateX(13px)
}