.ap-exam-list-container {
    margin: 1.6rem; 
    height: 200px;
    overflow-y: scroll;
}

.ap-exam-list-container > p {
    font-size: 1.4rem;
    height: 100%;
}

.ap-exam-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    gap: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (max-width: 599px) { 
    .ap-exam-list-container {
        margin: 1rem 1rem 2rem 1rem;
        height: 120px;
    }
}