.badge {
    background-color: #afb8c133;
    color: #24292f;
    padding: 4px 8px;
    text-align: center;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
}

.badgeTooltip {
    background-color: #495057 !important;
    border-color: #495057 !important;
    border-radius: 16px !important;
    padding: 10px 0px !important;
}

.badgeTooltip>h1 {
    margin-bottom: 8px;
}

.badgeTooltip>p {
    font-size: 1.4rem;
    margin: 0 0.5rem;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 180px;
}

.column {
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.ge {
    text-align: center;
    padding-top: 10px;
}