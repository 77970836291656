/**** Cancel Drop and Drag animation ****/
.program-container div[data-rbd-placeholder-context-id],
.ge-container div[data-rbd-placeholder-context-id] {
    display: none !important;
}

.program-container div[data-rbd-draggable-context-id],
.ge-container div[data-rbd-draggable-context-id] {
    transition: 0.001s !important;
}

.program-container div[data-rbd-droppable-context-id],
.ge-container div[data-rbd-droppable-context-id] {
    transition: 0.001s !important;
}

#tab-container {
    height: 64rem;
    border-radius: 1.5rem;
    box-shadow: 0rem 0.2rem 0.4rem 0rem rgb(0 0 0/60%);
    margin-bottom: 14rem;
}

.tab {
    flex-grow: 1;
    transition: background-color ease-in-out 0.4s;
    padding: 0.5rem;
    background-color: var(--primary-color-2);
    font-weight: 600;
    cursor: pointer;
}

.tab:hover {
    color: var(--accent-color-2);
}

.border-right {
    border-right: 1px solid white;
}

.active {
    border-top: 0.4rem solid var(--accent-color-2);
    background-color: var(--primary-color);
    color: var(--accent-color-2);
}

.hyperlink {
    display: flex;
    align-items: center;
    margin: 1rem 0.5rem 1.5rem 0.5rem;
}

.hyperlink > button {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.hyperlink > a,
.hyperlink > button > svg {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--accent-color-2);
}

.hyperlink > a {
    text-align: center;
}

.hyperlink>a:hover {
    text-decoration: underline;
    color: #FF934F;
}

#program-img {
    max-height: 500px;
    max-width: 100%;
    height: auto;
    width: auto;
}

.program-container {
    height: 48rem;
    overflow: scroll;
}

.ge-container {
    height: 60rem;
    overflow: scroll;
}

.loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 599px) {
    #tab-container {
        height: 53.7rem;
    }

    .tab {
        font-size: 1.5rem;
    }

    .hyperlink>a,
    .hyperlink>svg {
        font-size: 1.6rem;
    }

    .ge-container {
        height: 49rem;
    }

    .program-container {
        height: 39rem;
    }
}