.remove-btn{
    position:absolute;
    top: 0.2rem;
    right: 0.2rem;

    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;

    color: #FFFFFF;
}

.remove-btn > svg {
    transition: transform 250ms;
}

.remove-btn svg:hover { 
    border-radius: 50%;
    transform: rotate(-90deg);
}
