.required-course { height: 3.6rem; }
.quarter-course { margin: 0.2rem; height: 3.6rem; }

.quarter-course:hover .remove-btn,
.required-course:hover .remove-btn {
    opacity: 1;
    visibility: visible;
}

.course-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    overflow: hidden;
    border-radius: 1rem;
    width:100%;
    height: 3.6rem;
}

.course-id {
    overflow: hidden;
    padding-left: 0.6rem;
    cursor: pointer;
    font-size: 1.4rem;
    color:white;
}

.course-warning {
    color: white;
    margin-right: 12%;
    margin-left: 0.5rem;
}

.course-warning > svg {
    font-size: 1.9rem;
}
.course-warning-tooltip {
    border-radius: 10px !important;
    background-color: #1F1F1F !important;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
}

.unit {
    text-align: center;
    cursor: grab;

    padding-right: 1.6rem;
    padding-left: 0.8rem;

    font-size: 1.2rem;
    color:snow;
}

.course-tooltip{
    padding: 0 !important;
    background-color: transparent !important;
}


@media only screen and (max-width: 450px) {
    .unit { display: none; }
}

@media only screen and (max-width: 599px) { 
    .course-btn { height: 3.2rem; }
    .course-id { font-size: 1.2rem; }
}

@media print { 
    .course-id { font-size: 1.2rem; padding-left: 1.2rem; }
    .unit { padding-right: 1.2rem; padding-left: 0; }
    .quarter-course, .course-btn{ height: 2.6rem; }
    .course-warning > svg { font-size: 1.5rem; }
}