.navbar,
#brand,
.nav-list,
.nav-item,
.nav-link {
    display: flex;
    align-items: center;
}

.navbar {
    position: relative;
    justify-content: space-between;
    height: 94px;
    padding: 2rem 2.4rem;
    /* background-image: url("../../assets/theme/christmas/snowflake.png"); */
}

#brand { 
    position: relative;
    color: var(--primary-color); 
}

.nav-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.nav-item {
    margin: 0rem 1.2rem;
    padding-top: 0.5rem;
    font-size: 2rem;
}

.nav-item > button {
    font-size: 2rem;
    color: white;
}

.nav-item,
.nav-item > a {
    color: white;
}

.nav-item:hover, 
.nav-item:hover > a,  
.nav-item:hover > button{
    color: #307ABB;
}

.nav-link{ position: relative;}

.nav-link > svg {
    font-size: 1.4rem;
    transition: transform 500ms;
}

.nav-link:hover svg{
    transform: rotate(-180deg);
}

.nav-link:hover ul{
    display: block;
}

#menu-toggle { display: none; }

#menu-toggle > svg {
    font-size: 2.4rem;
    color: white;
}

@media only screen and (max-width: 740px) {
    #menu-toggle {
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .nav-item, 
    .nav-item > a, 
    .nav-item > button {
        opacity: 0;
        transition: opacity .1s ease;
    }

    .nav-item {
        justify-content: center;
        margin: 0;
        padding-top: 2rem;
        overflow: visible !important;
    }

    .nav-list-show > .nav-item, 
    .nav-list-show .nav-item > a,  
    .nav-list-show .nav-item > button {
        opacity: 1;
    }

   .nav-list > li:first-of-type {
        width: 100%;
        border-top: 1px solid white;
    } 
}
