.input-container {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0rem 5% 0.5rem 5%;
} 

.browse-container {
    width: 100%;
} 

/* add-course-btn */
.add-course-btn {
    top: 50%;
    right: 3px;
    transform: translateY(-50%);

    border-radius: 50%;
    padding: 0.7rem;
   
    background-color: var(--accent-color-2);
    line-height: 0;
    color:white;
}

.add-course-btn > svg {
    font-size: 1.8rem;
}

/* message */
.message-container {
    height: 1.4rem;
}

.fade-message {
    -moz-animation: fadeIn 1s ease-in 4s forwards;
    -webkit-animation: fadeIn 1s ease-in 4s forwards;
    -o-animation: fadeIn 1s ease-in 4s forwards;
    animation: fadeIn 1s ease-in 4s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    to {opacity: 0; visibility: hidden;}
}

@-webkit-keyframes fadeIn {
    0% {opacity: 1; visibility: visible;}
    100% {opacity: 0; visibility: hidden;}
}