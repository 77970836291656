#footer {
    position: relative;
    display: grid;
    justify-items: center;
}

.home-footer {
    padding: 5rem 4rem 0 4rem;
    background-color: var(--black-2);
    color: var(--primary-color);
}

.header {
    height: 3.2rem;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

#about-us-content > p {
    line-height: 1.2;
    font-size: 1.4rem;
    text-align: justify;
    margin-bottom: 1rem;
}

#zot-tree{
    position: absolute;
    top:-12.8rem;
    left: 50%;
    transform: translateX(-50%);
    transition: left 0.5s;
}

.ornament {
    position: absolute;
    width: 18px;
    height: 18px;
}

#greenBall {
    left:80px;
    bottom: 12px;
}

#yellowRedBall {
    left: 96px;
    bottom: 42px;
}

#yellowBall {
    right: 38px;
    top: 28px;
}   

#greenStar {
    right:-5px;
    bottom: 25px;
}

#redStarBall {
    right: 16px;
    bottom: 50px;
}

#greenRedBall {
    right: 36px;
    bottom: 20px;
}

#greenStripedBall {
    left: 70px;
    top: 50px;
}
#yellowStar {
    left: 100px;
    top: 36px;
}
#topStar {
    position: absolute;
    height: 31px;
    width: 32px;
    right:49px;
    top: -16px;
}

.merry-christmas {
    position: absolute;
    bottom: 54px;
    height: 128px;
    width: 168px;
}

#logo-container {
    margin: 5rem 0rem 2rem 0rem;
}

#logo-image {
    position: absolute;
    top:-5px;
    left: 100px;

    height: 3.6rem;
    width: 3.6rem;
}

.list-container {
    display: grid;
    grid-template-columns: 1fr; 
}

.list-contact-us{
    row-gap: 3rem;
}

.list-author {
    row-gap: 1rem;
}

.name {
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: 400;
}

.list, #copyright {
    display: flex;
    align-items: center;
}

.list {margin-top: 0.5rem;}

.item > a > svg {
    font-size: 2rem;
    color: var(--primary-color);
}

.author-icon > li {
    width: 2.8rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.contact-us {
    display: flex;
    align-items: center;
}

.contact-us-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
}


#copyright {
    grid-column: 1/-1;
    flex-direction: column;

    margin-top: 6rem;
}

#copyright > p { font-size:1.4rem; margin: 1rem 0rem;}


@media only screen and (max-width: 799px) {
    .header {
        text-align: center;
    }

    .name {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .list {
        justify-content: center;
    }

    #contact-us, #author {
        margin-top: 4rem;
    }

    #author {
        margin-bottom: 10rem;
    }

    #about-us-content > p {
        padding-left: 6%;
        padding-right: 6%;
    }
}

@media only screen and (min-width: 800px) { 
    #footer {
        grid-template-columns: 1.5fr 1.5fr 1fr;
        column-gap: 4rem;
    }

    #zot-tree { left: 75%; transform: translateX(0); }

    #logo-container { margin: 2rem 0rem;}
    #copyright {margin-top: 4rem;}
}

@media only screen and (min-width: 992px) { 
    #footer{ column-gap: 3rem;}
}