details { 
    border-radius: 1.6rem;
    box-shadow: 0rem 0.2rem 0.4rem 0rem rgb(0 0 0/60%);
    background-color: var(--primary-color-2);
}

summary { list-style-type: none;}
summary:focus { outline: none !important;}
summary::-webkit-details-marker { display: none; }

details[open] .right-icon > svg{
    transform: rotate(90deg);
}

.quarter-droppable-area {
    display: none;
    min-height: 0px;
}

details[open] .quarter-droppable-area {
    display: block;
    min-height: 8.4rem;
}

.accordion {
    display:flex;
    align-items: center;
    cursor: pointer;
}
.year:hover .remove-year-btn {
    display: block;
}
.accordion:hover {color: var(--accent-color-2)}
.accordion:hover .remove-year-btn{
    visibility: visible;
    opacity: 1;
}

.section-header {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}

.year-header, .section-header {
    flex-grow: 2;
    font-weight: 700;
}

.right-icon {
    line-height: 0;
    transition: transform var(--transition-time);
}

.right-icon > svg {font-size: 1.4rem;}

.section-body { 
    padding: 1.6rem 0.8rem;
    border-bottom-left-radius: 1.6rem;
    border-bottom-right-radius: 1.6rem;
    background-color: white;
}

/* quater */
.quarters-wrapper {
    display: grid;
}

.quarter-header-fall { grid-area: hfall;}
.quarter-header-winter {grid-area: hwinter;}
.quarter-header-spring {grid-area: hspring;}
.quarter-header-summer {grid-area: hsummer;}
.fall {grid-area: fall;}
.winter {grid-area: winter;}
.spring{grid-area: spring;}
.summer{ grid-area: summer;}

.quarter-header {
    padding: 0.15rem;
   
    border-bottom: 0.2rem solid var(--border-color);
    border-top: 0.2rem solid var(--border-color);
    background-color: #F8F8F8;

    font-weight: 500;
    text-align: center;
}

/* AccordionDetail */
.accordion-detail, .sub-area, .grid > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, 10.8rem);
    gap: 1.8rem;
}

.sub-area {
    grid-column: 1 / -1;
}

.or-container > span {
    z-index: 0;
    top: 0.8rem;
    left: 11.1rem;
    font-size: 1.4rem;
} 

@media only screen and (max-width: 599px) { 
    summary { padding: 0.6rem 0.4rem;}
    .section {margin: 2rem 1rem;}

    .quarters-wrapper {
        grid-template-areas:"hfall" "fall"
                            "hwinter" "winter"    
                            "hspring" "spring"
                            "hsummer" "summer";
        grid-template-columns: 1fr;
    }

    .summer { 
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

    .quarter-droppable-area {
        min-height: 10rem;
    }

    .accordion-detail, .sub-area {
        grid-template-columns: repeat(auto-fill, 9rem);
        column-gap: 1.4rem;
        row-gap: 1.2rem;
    }

    .or-container > span {left: 9.1rem; font-size: 1.2rem;}
}

@media only screen and (min-width: 600px) {
    summary {padding: 0.6rem 1rem;}
    .section { margin: 0rem 1.6rem 2rem 1.6rem; }

    .quarters-wrapper{
        grid-template-areas:"hfall hwinter"
                            "fall winter"    
                            "hspring hsummer"
                            "spring summer";
        grid-template-columns: 1fr 1fr;
    }

    .quarter-header-fall, .quarter-header-spring, 
    .fall, .spring {
        border-right: 0.2rem solid var(--border-color);
    }

    .spring { border-bottom-left-radius: 1.5rem;}
    .summer { border-bottom-right-radius: 1.5rem; }
}

@media only screen and (min-width: 800px) { 
    .quarters-wrapper {
        grid-template-areas:"hfall hwinter hspring hsummer"
                            "fall winter spring summer";
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .spring {border-bottom-left-radius: 0rem;}
    .fall {border-bottom-left-radius: 1.5rem;}

    .quarter-header-winter, .winter {
        border-right: 0.2rem solid var(--border-color);
    }
}

@media only screen and (min-width: 992px) {
    .section-body{padding: 1.6rem 1.2rem;}
}

@media print {
    details {
        box-shadow: none;
        border: 0.2rem solid #D6D6D6 !important;
        margin-bottom: 2rem !important;
    }
    summary {
        padding: 0.2rem 1rem;
    }
    .quarters-wrapper {
        grid-template-areas:"hfall hwinter hspring hsummer"
                            "fall winter spring summer";
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .year-header {font-size: 1.4rem;}
    .quarter-header { padding: 0.25rem; font-size: 1.2rem;}

    .quarter-header-fall, .quarter-header-spring, .quarter-header-winter,
    .fall, .spring, .winter {
        border-right: 0.2rem solid var(--border-color);
    }

    .fall {border-bottom-left-radius: 1.5rem;}
    .summer { border-bottom-right-radius: 1.5rem; }
}