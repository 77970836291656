@media only screen and (max-width: 740px) {
    #home  .nav-list {
        position:absolute;
        z-index: 2;
        top: 80px;
        left: 0;

        width: 100%;
        height: 0;

        display:block;
        overflow: hidden;

        opacity: 1;
        background-color: #1F1F1F;
        transition: all .4s ease;
    }

    #home .nav-list-show {
        height: 200px;
        opacity: 1;
        transform: translateY(0);
        overflow: visible;
    }
}