.ap-exam-units-wrapper {
    display: flex;
    align-items: center;
    height: 46px;

    padding-left: 1.6rem;
    padding-right: 1.6rem;

    border-radius: 2rem;
    border: 1px solid var(--accent-color-2);

    color: var(--accent-color-2);
    font-size: 1.6rem;
}

.ap-exam-note {
    margin: 5px 10px;
}

.ap-exam-note, .ap-exam-note > a {
    color: var(--accent-color-2);
    font-size: 1.4rem;
    text-align: center;
}

.ap-exam-note > a {
    font-weight: bold;
    text-decoration: underline;
}