.course-card-background {
    position: fixed;
    z-index:3;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    cursor: default;

    background-color:rgba(0, 0, 0, 0.2);
}

.course-card {
    border: 0.5rem solid;
    border-radius: 1.6rem;
    background-color: white;

    width: 32rem;

    margin-top: 0.9rem;
}

.course-card-before{
    position: absolute;
    z-index: -1;
    top: 0.6rem;
    left: 50%;

    width: 2.5rem;
    height: 2.5rem;

    transform: rotate(45deg) translateX(-50%);
    margin-top: 0.8rem;
}

.course-card-header {
    padding: 0.6rem;
    padding-right: 3rem;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
    font-size: 1.5rem;
}

.course-card-body {
    padding: 0.5rem;
}

.course-card-body > p {
    margin: 0.8rem 0rem;
    font-size: 1.4rem;
}

.close-card {
    top: 4px;
    right: 4px;
}

.close-card > svg {
    font-size: 2rem;
}

.close-card:hover > svg {color: var(--primary-color);}

.course-card-background, .close-card {
    display: none;
}

.course-card {
    border: 0.2rem solid;
    border-radius: 1rem;
    width: 25rem;
}

.course-card-header {padding-right: 0.6rem;}
