/***************************************************************************** 
                                Table of Contents
1. Global Styles
    1.1 root, body, code, html, typography, a, button, svg
    1.2 scroll bar
    1.3 common class
**************************************************************************************/

/* 1. Global Styles */
:root {
    --transition-time: 400ms;
    /* text */
    --text-color: #000000; /* black */
    --vc-spec-text-primary: #000000;
    --vc-spec-text-second: #6c757d;

    /* background */
    --border-color: #D6D6D6;  /* light gray*/
    --primary-color:#FFFFFF; /* white */
    --primary-color-2: #F2F2F2; /* cultured */
    --black-2:#1F1F1F; /* Eerie Black */
    --accent-color-1:#DF2935; /* Rose Madder */
    --accent-color-2:#307ABB; /* French Blue */
    --red: #DA1E37; /* note */
}

* {
    box-sizing: border-box;
    font-display: swap;
    font-family: 'Roboto','Times New Roman',Times, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
}
  
code {font-family: 'Roboto','Times New Roman',Times, serif;}

html {font-size: 10px; color:#1F1F1F}

html, body, #root { background-color: var(--primary-color);}

h1, h2, h3, h4, h5, h6, p, div, ul, button {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
}

p {word-break: break-word;}

a, button, svg { cursor: pointer; }

ul {list-style-type: none;}

a {text-decoration: none;}

button {
    background: transparent;
    border: none;
}

svg { font-size: 10px; }

::-webkit-scrollbar
{
    width: 9px;   /* for vertical scrollbars   */
    height: 10px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track { background-color: transparent;}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.16);
}

/* common class */
.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.flex-column { flex-direction: column;}
.flex-row { flex-direction: row; }

.relative {position: relative;}
.absolute {position: absolute;}
.static {position: static;}
.fixed {position: fixed;}

.round-top-left { border-top-left-radius: 1.5rem; }
.round-top-right { border-top-right-radius: 1.5rem; }

.__react_component_tooltip.show { opacity: 1 !important; }

.text-bold { font-weight: bold; }

/* button */
.btn-outlined { 
    border-radius: 50%;
    line-height: 0;
    color: var(--accent-color-2);
}

.btn-outlined:hover {
    box-shadow: 0 0 3px 3px var(--accent-color-2);
    background-color: var(--accent-color-2);
    color: white;
}

/* modal */
.modal-background {
    position: fixed;
    z-index: 4;
    top: 0px;
    left: 0px;
    overflow: scroll;

    background-color:rgba(0, 0, 0, 0.20);
    width: 100%;
    height: 100%;
}

.Toastify__toast-body {
    white-space: pre-line;
}