#schedule {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: visible;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
}

details:last-of-type {
    margin-bottom: 0rem !important;
}

#left-side{
    margin-bottom: 2rem;
}

#left-side ul {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
}

.print-programs, .print-units {display: none;}

#chalkboard {
    float: right;
    width: 15rem;
    height: 11rem;
}

.unit-text {
    position: absolute;
    right: 34px;
    bottom: 16px;
    text-align: center;
    color: white;
}

.unit-text p {
    margin-bottom: 0.5rem;
    font-size: 16px;
}

.bells {
    position: absolute;
    right: 54px;
    top: -2px;
    width: 40px;
    height: 38px;
}

@media only screen and (max-width: 599px) { 
    #schedule {
        height: 54rem;
        overflow-x: hidden;
        padding: 0 0.3rem 0 0.2rem;
    }
}

@media only screen and (min-width: 600px) { 
    #schedule {height: 65rem;}
}

@media only screen and (min-width: 800px) { 
    #schedule {overflow: visible; height: auto; margin-bottom: 0;}
}

@media print {
    div, details, summary, p {
        break-inside: avoid !important;
        page-break-inside: avoid !important;
        overflow: visible !important;
    }

    #schedule {
        display: block !important;
        width: 766px;
        overflow: visible !important;
        margin: 2.5rem auto;
        padding: 0rem;
    }

    .print-units {
        display: flex;
        float: right;
        margin-top : 1rem;
        padding-right: 1rem;
    }

    .print-programs {
        display: block;
        width: 750px;
        padding-left: 1rem;
        margin-bottom: 1rem;
    }

    .print-units p, .print-programs > p {
        margin-right: 0.5rem;
        font-size: 14px;
    }

    .print-programs > p {
        margin-bottom: 0.5rem;
    }

    img, .unit-text {
        display: none;
    }
}