.dropdown-menu {
    position: absolute;
    z-index: 999;
    top: 20px;
    left: 50%;
    transform: translateX(-55%);

    display: none;
    width: 200px;
   
    padding-top: 15px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background-color: #1F1F1F;
    cursor: pointer;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 16px;
}

.dropdown-link > a {
    text-decoration: none;
    color: white;
}

.dropdown-link > a:hover{
    color:#307ABB;
} 

@media only screen and (max-width: 800px) {
    .dropdown-menu {  
        transform: translateX(-50%);
        padding-top: 30px;
    } 
}